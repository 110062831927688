import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import { CALLTOACTION_BUTTON_NAME } from 'components/AemComponent/analyticsConstants';
import { Button } from '@johnlewispartnership/wtr-ingredients/ingredients/Button';
import AnchorLink from 'components/AnchorLink';

import promoClick from 'analytics/aem/promo-click';

import styles from './index.scss';

const CallToActionButton = ({
  align,
  analytics,
  analyticsTags,
  ariaLabel,
  componentId,
  cqResponsiveClasses,
  link,
  position,
  theme,
  width,
}) => {
  if (!(link?.text && link?.url)) {
    return null;
  }

  const onClick = () => {
    promoClick({
      ...analytics,
      id: componentId,
      name: analytics?.promoName,
      position,
      creative: CALLTOACTION_BUTTON_NAME,
      linkUrl: link.url,
      newWindow: link.newWindow,
    });
  };

  return (
    <div
      id={componentId}
      className={classnames(cqResponsiveClasses, styles.marginBottom, styles[align])}
      {...analyticsTags}
    >
      <Button
        type={undefined}
        component={AnchorLink}
        aria-label={ariaLabel}
        to={link?.url}
        inNewTab={link.newWindow}
        width={width}
        theme={theme === 'primaryLight' ? 'primaryWhite' : theme}
        label={link?.text}
        onClick={onClick}
      />
    </div>
  );
};

CallToActionButton.propTypes = {
  align: PropTypes.string,
  analytics: PropTypes.shape({
    promoName: PropTypes.string,
    promoType: PropTypes.string,
    promoPeriod: PropTypes.string,
    bannerPurpose: PropTypes.string,
    buyingOfficeAndGroup: PropTypes.string,
  }),
  analyticsTags: PropTypes.shape({}),
  ariaLabel: PropTypes.string,
  componentId: PropTypes.string,
  cqResponsiveClasses: PropTypes.arrayOf(PropTypes.string),
  link: PropTypes.shape({
    newWindow: PropTypes.bool,
    text: PropTypes.string,
    url: PropTypes.string,
  }),
  position: PropTypes.string,
  theme: PropTypes.string,
  width: PropTypes.string,
};

CallToActionButton.defaultProps = {
  align: undefined,
  analytics: undefined,
  analyticsTags: undefined,
  ariaLabel: undefined,
  componentId: undefined,
  cqResponsiveClasses: undefined,
  link: undefined,
  position: undefined,
  theme: undefined,
  width: undefined,
};

CallToActionButton.authorConfig = {
  emptyLabel: 'CTA Button',
  isEmpty: ({ link }) => !link?.text,
};

CallToActionButton.displayName = CALLTOACTION_BUTTON_NAME;

export default CallToActionButton;
